















































import { Observer } from "mobx-vue";
import { Component, Vue, Provide, Ref, Inject } from "vue-property-decorator";
import { CharacterDetailViewmodel } from "../viewmodels/character-detail-viewmodel";
import { confirmDialogController } from "@/components/confirm-dialog/confirm-dialog-controller";
import { snackController } from "@/components/snack-bar/snack-bar-controller";

@Observer
@Component({
  components: {
    TimeAuctionForm: () => import("../components/time-aution-form.vue"),
    FixedPriceForm: () => import("../components/fixed-price-form.vue"),
    PreviewCharacter: () => import("../components/preview-character.vue"),
  },
})
export default class SellMonsterDialog extends Vue {
  @Inject() vm!: CharacterDetailViewmodel;
  @Ref("form") form: any;

  walletAddress?: string = "";
  confirmDialogController = confirmDialogController;

  cancel() {
    this.walletAddress = "";
    this.vm.setTransferDialog(false);
  }

  transfer() {
    if (this.form.validate()) {
      this.confirmDialogController.confirm({
        content: `Your asset will be transferred to address <span class="accent--text">${this.walletAddress}</span>. As a result, it will no longer be in your wallet nor will show up in your character list.`,
        title: "Confirm transfer",
        doneText: "Confirm",
        doneCallback: async () => {
          const response = (await this.vm.transfer(this.walletAddress)) as any;
          if (!response || !response!.status || !response.transactionHash)
            return;
          snackController.success(
            `You have transferred successfully! 
            <br> Please wait for a few minutes until your transaction is confirmed! 
            <br> Click here to view <a href="${
              process.env.VUE_APP_CHAIN_ID === "97"
                ? "https://testnet.bscscan.com/tx/"
                : "https://bscscan.com/tx/"
            }${response.transactionHash}">Transaction detail</a>`
          );
          this.cancel();
          this.$router.push("/my-character");
        },
      });
    }
  }
}
